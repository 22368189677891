import {Pipe, PipeTransform} from '@angular/core';
import {Structure} from '../objects';

@Pipe({
  name: 'libelleStructure'
})
export class LibelleStructurePipe implements PipeTransform {

  transform(structure: Structure): string {
    let res: string = null;
    if (structure) {
      if (structure.libelleLong.length < 30) {
        res = structure.libelleLong;
      } else if (structure.libelleCourt && structure.libelleCourt.length < 30) {
        res = structure.libelleCourt;
      } else {
        res = structure.libelleLong.substr(0, 30);
      }
    }
    return res;
  }

}
