<mat-paginator
  [length]="persons.length"
  [pageSize]="pageSize"
  [pageSizeOptions]="pageSizeOptions"
  (page)="onPageEvent($event)">
</mat-paginator>

<mat-accordion>
  <mat-expansion-panel *ngFor="let person of personsFilter" (expandedChange)="expand(person)">
    <mat-expansion-panel-header>
      <mat-panel-title>{{person.nomComplet}}</mat-panel-title>
    </mat-expansion-panel-header>

    <mat-card-content>
      <span class="ml-4 mb-2 d-block" *ngIf="person.telephone">{{person.telephone}}</span>
      <span class="ml-4 mb-2 d-block" *ngIf="person.mail"><a [href]="'mailto:' + person.mail">{{person.mail}}</a></span>

      <span class="ml-4 d-block">Service(s) :
              <ul>
                <li
                  class="clickable"
                  (click)="onSelectedStucture(struct.structureId)"
                  *ngFor="let struct of person.structures">{{struct.libelleLong ? struct.libelleLong : struct.structureId}}
                </li>
               </ul>
        </span>
    </mat-card-content>
  </mat-expansion-panel>
</mat-accordion>
