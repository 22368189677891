import {Component, Input, OnChanges} from '@angular/core';
import {Adresse} from '../../objects';
import * as L from 'leaflet';
import {Map, Icon, IconOptions, LatLng, Marker} from 'leaflet';

@Component({
  selector: 'app-map',
  template: '<div id="map"  style="width: 550px; height: 470px;"></div>'
})
export class MapComponent implements OnChanges {

  @Input() adresse: Adresse;
  @Input() readonly: boolean;

  map: Map;
  icon: Icon<IconOptions>;
  marker: Marker;

  constructor() {
    this.readonly = true;
  }

  ngOnChanges(): void {
    if (this.adresse && this.adresse.latitude && this.adresse.longitude) {
      const latLng: LatLng = new LatLng(this.adresse.latitude, this.adresse.longitude);
      this.map = L.map('map').setView(latLng, 15);
      this.icon = L.icon({
        iconUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.2.0/images/marker-icon.png',
        iconSize: [24, 36],
        iconAnchor: [12, 36]
      });

      L.tileLayer('http://{s}.tile.osm.org/{z}/{x}/{y}.png', {attribution: 'Université de Bordeaux'}).addTo(this.map);

      this.marker = L.marker(latLng, {
        icon: this.icon,
        draggable: !this.readonly
      }).bindPopup(this.adresse.typeAdresse).addTo(this.map).openPopup();
    }
  }
}
