import {APP_INITIALIZER, NgModule} from '@angular/core';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';

import {AppRoutingModule} from './app-routing.module';
import {HttpInterceptor} from './http.interceptor';

import {AppComponent} from './app.component';
import {AuthenticationService} from './services/authentication.service';
import {ConfigAssetLoaderService} from './configAssetLoader.service';
import {LoaderService} from './services/loader.service';
import {MAT_SNACK_BAR_DEFAULT_OPTIONS, MatSnackBarModule} from '@angular/material/snack-bar';
import {AnnuaireDesStructuresComponent} from './pages/annuaire-des-structures/annuaire-des-structures.component';
import {ngxLoadingAnimationTypes, NgxLoadingModule} from 'ngx-loading';
import {StructureService} from './services/structure.service';
import {ArborescenceComponent} from './components/arborescence/arborescence.component';
import {MatInputModule} from '@angular/material/input';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {ReactiveFormsModule} from '@angular/forms';
import {MatCardModule} from '@angular/material/card';
import {MatExpansionModule} from '@angular/material/expansion';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {ListePersonnelComponent} from './components/liste-personnel/liste-personnel.component';
import {LdapService} from './services/ldap.service';
import {MatTabsModule} from '@angular/material/tabs';
import {MapDialogComponent} from './components/map-dialog/map-dialog.component';
import {MAT_DIALOG_DEFAULT_OPTIONS, MatDialogModule} from '@angular/material/dialog';
import {MatPaginatorModule, MatPaginatorIntl} from '@angular/material/paginator';
import {MapComponent} from './components/map-dialog/map.component';
import {MatChipsModule} from '@angular/material/chips';
import {StructureDetailsComponent} from './components/structure-details/structure-details.component';
import {MatButtonModule} from '@angular/material/button';
import {GlobalStateService} from './services/global-state.service';
import {LibelleStructurePipe} from './pipes/libelle-structure.pipe';
import {MatSidenavModule} from '@angular/material/sidenav';
import {TreeModule} from 'primeng/tree';
import {OverlayPanelModule} from 'primeng/overlaypanel';
import {getCustomPaginator} from './custom-paginator';

@NgModule({
  declarations: [
    AppComponent,
    AnnuaireDesStructuresComponent,
    ArborescenceComponent,
    LibelleStructurePipe,
    ListePersonnelComponent,
    MapDialogComponent,
    MapComponent,
    StructureDetailsComponent,
  ],
  imports: [
    AppRoutingModule,
    BrowserModule,
    BrowserAnimationsModule,
    FontAwesomeModule,
    HttpClientModule,
    MatAutocompleteModule,
    MatButtonModule,
    MatCardModule,
    MatChipsModule,
    MatDialogModule,
    MatExpansionModule,
    MatInputModule,
    MatPaginatorModule,
    MatSidenavModule,
    MatSnackBarModule,
    MatTabsModule,
    NgxLoadingModule.forRoot({
      animationType: ngxLoadingAnimationTypes.wanderingCubes,
      backdropBackgroundColour: 'rgba(0,0,0,0.1)',
      backdropBorderRadius: '4px',
      primaryColour: '#ffffff',
      secondaryColour: '#ffffff',
      tertiaryColour: '#ffffff',
      fullScreenBackdrop: true
    }),
    TreeModule,
    ReactiveFormsModule,
    OverlayPanelModule,
  ],
  providers: [
    AuthenticationService,
    GlobalStateService,
    LdapService,
    LoaderService,
    StructureService,
    {provide: HTTP_INTERCEPTORS, useClass: HttpInterceptor, multi: true},
    {provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: {hasBackdrop: true}},
    {provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, useValue: {duration: 2500}},
    {
      provide: APP_INITIALIZER,
      useFactory: (configService: ConfigAssetLoaderService) => () => configService.loadConfigurations().toPromise(),
      deps: [ConfigAssetLoaderService],
      multi: true
    },
      { provide: MatPaginatorIntl, useValue: getCustomPaginator() }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
