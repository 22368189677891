<ngx-loading [show]="loading | async"></ngx-loading>

<!-- NAVBAR ANNUAIRE DE L'ENT -->
<nav *ngIf="displayInverseTab !== null" class="navbar navbar-expand-lg navbar-light bg-light">
  <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>
  <div class="collapse navbar-collapse" id="navbarSupportedContent">
    <ul class="navbar-nav mr-auto">
      <li class="nav-item"><a class="nav-link align-middle" href="https://apps-ent.u-bordeaux.fr/annuaire/v2/index.php#">{{'personnels'.toUpperCase()}}</a></li>
      <li class="nav-item active" (click)="refresh()"><a class="nav-link" [routerLink]="['/structures']">{{'structures'.toUpperCase()}}</a></li>
      <li class="nav-item"><a class="nav-link" href="https://apps-ent.u-bordeaux.fr/annuaire/v2/indexETU.php">{{'étudiants'.toUpperCase()}}</a></li>
      <li class="nav-item" *ngIf="displayInverseTab"><a class="nav-link" href="https://apps-ent.u-bordeaux.fr/annuaire/v2/indexINV.php">{{'inversé'.toUpperCase()}}</a></li>
    </ul>
  </div>
</nav>

<div class="p-2">
  <router-outlet></router-outlet>
</div>

<footer class="row justify-content-center">
  <span class="small">v.{{ version }}</span>
</footer>
