import {Component, Input, OnChanges} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {StructureService} from '../../services/structure.service';
import {Adresse, Code, Contact, Description, Domaine, LdapAccount, Structure, Tutelle} from '../../objects';
import {LoaderService} from '../../services/loader.service';
import {LdapService} from '../../services/ldap.service';
import {GlobalStateService} from '../../services/global-state.service';
import {MapDialogComponent} from '../map-dialog/map-dialog.component';
import {MatDialog} from '@angular/material/dialog';

@Component({
  selector: 'app-structure-details',
  templateUrl: './structure-details.component.html',
  styleUrls: ['./structure-details.component.scss']
})
export class StructureDetailsComponent implements OnChanges {

  @Input() id: number;

  structure: Structure;
  ascendant: Structure[];
  description: Description;
  children: Structure[];
  persons: LdapAccount[];
  responsables: string;
  adresses: Adresse[];
  contacts: Contact[];
  tutelles: Tutelle[];
  domaines: Domaine[];
  codes: Code[];

  display: boolean;

  constructor(private route: ActivatedRoute,
              private globalStateService: GlobalStateService,
              private structureService: StructureService,
              private loaderService: LoaderService,
              private ldapService: LdapService,
              public dialog: MatDialog) {

    this.globalStateService.subscribe('refresh', (refreshEvent: boolean) => {
      if (refreshEvent) {
        this.display = false;
        this.globalStateService.notifyDataChanged('refresh', false);
      }
    });
  }

  ngOnChanges() {
    if (this.id) {
      this.onSelectedStucture(this.id);
    }
  }

  onSelectedStucture(structureId: number): void {
    this.loaderService.start();
    this.display = false;
    this.structureService.getStructureById(structureId).subscribe(res => {
      this.structure = res;
      this.init();
    });
  }

  private init(): void {
    this.getPersonnels();
    this.getDescription();
    this.getAdresses();
    this.getContacts();
    this.getResponsables();
    this.getDomaines();
    this.getTutelles();
    this.getCodes();

    Promise.all([
      this.getAscendants(),
      this.getChildren()
    ]).then(() => {
      this.loaderService.stop();
      this.display = true;
    });
  }

  displayMap(adresse: Adresse) {
    this.dialog.open(MapDialogComponent, {
      data: {
        structure: this.structure,
        adresse
      }
    });
  }

  private getAscendants(): Promise<void> {
    this.ascendant = [];
    if (this.structure.liensParent[0]) {
      return new Promise<void>(resolve => {
        this.structureService.getAscendantsByStructureId(this.structure.structureId).toPromise()
          .then(res => {
            this.ascendant = res;
            resolve();
          })
          .catch(() => {
            resolve();
          });
      });
    }
  }

  private getChildren(): Promise<void> {
    this.children = [];
    return new Promise<void>(resolve => {
      this.structureService.getChildrenByStructureId(this.structure.structureId).toPromise()
        .then(res => {
          this.children = res;
          resolve();
        })
        .catch(() => {
          resolve();
        });
    });
  }

  private getDescription(): Promise<void> {
    this.description = null;
    return new Promise<void>(resolve => {
      this.structureService.getDescriptionByStructureId(this.structure.structureId).toPromise()
        .then(res => {
          this.description = res;
          resolve();
        })
        .catch(() => {
          resolve();
        });
    });
  }

  private getResponsables(): Promise<void> {
    this.responsables = '';
    return new Promise<void>(resolve => {
      this.structureService.getResponsablesByStructureId(this.structure.structureId).toPromise()
        .then(res => {
          this.responsables = '';
          res.forEach(r => this.responsables += r.nom.toUpperCase() + ' ' + r.prenom + ', ');
          this.responsables = this.responsables.substr(0, this.responsables.length - 2);
          resolve();
        })
        .catch(() => {
          resolve();
        });
    });
  }

  private getCodes(): Promise<void> {
    this.codes = [];
    return new Promise<void>(resolve => {
      this.structureService.getCodesByStructureId(this.structure.structureId).toPromise()
        .then(res => {
          this.codes = res;
          resolve();
        })
        .catch(() => {
          resolve();
        });
    });
  }

  private getDomaines(): Promise<void> {
    this.domaines = [];
    return new Promise<void>(resolve => {
      this.structureService.getDomainesByStructureId(this.structure.structureId).toPromise()
        .then(res => {
          this.domaines = res;
          resolve();
        })
        .catch(() => {
          resolve();
        });
    });
  }

  private getTutelles(): Promise<void> {
    this.tutelles = [];
    return new Promise<void>(resolve => {
      this.structureService.getTutellesByStructureId(this.structure.structureId).toPromise()
        .then(res => {
          this.tutelles = res;
          resolve();
        })
        .catch(() => {
          resolve();
        });
    });
  }

  private getAdresses(): Promise<void> {
    this.adresses = [];
    return new Promise<void>(resolve => {
      this.structureService.getAdressesByStructureId(this.structure.structureId).toPromise()
        .then(res => {
          this.adresses = res;
          resolve();
        })
        .catch(() => {
          resolve();
        });
    });
  }

  private getContacts(): Promise<void> {
    this.contacts = [];
    return new Promise<void>(resolve => {
      this.structureService.getContactsByStructureId(this.structure.structureId).toPromise()
        .then(res => {
          this.contacts = res;
          resolve();
        })
        .catch(() => {
          resolve();
        });
    });
  }

  private getPersonnels(): Promise<void> {
    this.persons = [];
    return new Promise<void>(resolve => {
      this.ldapService.getAllPersonsByStructureId(this.structure.structureId).toPromise()
        .then(res => {
          this.persons = res;
          resolve();
        })
        .catch(() => {
          resolve();
        });
    });
  }
}
