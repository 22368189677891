import {Component, EventEmitter, Input, OnChanges, Output} from '@angular/core';
import {LdapAccount, Structure} from '../../objects';
import {PageEvent} from '@angular/material/paginator';
import {StructureService} from '../../services/structure.service';

@Component({
  selector: 'app-liste-personnel',
  templateUrl: './liste-personnel.component.html',
  styleUrls: ['./liste-personnel.component.scss']
})
export class ListePersonnelComponent implements OnChanges {

  @Output() selectedStructure: EventEmitter<number> = new EventEmitter<number>();

  @Input() structure: Structure;
  @Input() persons: LdapAccount[];

  personsFilter: LdapAccount[];
  structures: Structure[];
  pageSize: number;
  pageSizeOptions: number[];

  constructor(private structureService: StructureService) {
    this.structures = [];
    this.personsFilter = [];
    this.pageSize = 50;
    this.pageSizeOptions = [10, 50, 100];
  }

  ngOnChanges(): void {
      this.personsFilter = this.persons.slice(0, this.pageSize);
  }

  onSelectedStucture(structureId: number): void {
    this.selectedStructure.emit(structureId);
  }

  expand(person: LdapAccount) {
    this.structureService.getAffectationsByIdnum(person.uid).subscribe(structures => person.structures = structures);
  }

  onPageEvent(pageEvent: PageEvent) {
    const start = pageEvent.pageIndex * pageEvent.pageSize;
    this.personsFilter = [];
    this.personsFilter = this.persons.slice(start, start + pageEvent.pageSize);
  }
}
