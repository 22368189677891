import {Injectable} from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpRequest, HttpResponse} from '@angular/common/http';
import {Observable} from 'rxjs';
import {tap} from 'rxjs/operators';
import {AuthenticationService} from './services/authentication.service';
import {ConfigAssetLoaderService, Configuration} from './configAssetLoader.service';

@Injectable()
export class HttpInterceptor implements HttpInterceptor {

  conf: Configuration;

  constructor(public authenticationService: AuthenticationService, private configAssetLoaderService: ConfigAssetLoaderService) {
    this.configAssetLoaderService.loadConfigurations().subscribe(res => this.conf = res);
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const started = Date.now();

    if (this.conf) {
      request = Object.assign(request,
        new HttpRequest(
          request.method as any,
          this.conf.apiUrl + request.url,
          request.body,
          {
            headers: request.headers,
            reportProgress: request.reportProgress,
            params: request.params,
            responseType: request.responseType,
            withCredentials: request.withCredentials
          }
        )
      );

      const jwt = localStorage.getItem('token');
      if (jwt) {
        request = request.clone({
          setHeaders: {
            Authorization: jwt
          }
        });
      }
    }

    return next.handle(request).pipe(
      tap(event => {
          if (event instanceof HttpResponse) {
            const elapsed = Date.now() - started;
            // console.log(`Request for ${request.urlWithParams} took ${elapsed} ms.`);
          }

        }, error => {
          if (error instanceof HttpErrorResponse) {
            if (error.status === 403) {
              this.authenticationService.forceRedirectionToCasLogin();

            } else if (error.status === 418) {
              console.log(error.headers.get('X-Warnings'));
            } else {
              localStorage.clear();
              this.authenticationService.forceRedirectionToCasLogin();
            }
          }
        }
      )
    );
  }
}
