<mat-sidenav-container>

  <mat-sidenav #sidenav mode="side" [(opened)]="openedSidenav">
    <div class="row">
      <mat-form-field class="col-12 p-4">
        <input type="text" placeholder="Recherche" aria-label="Structure" matInput
               [formControl]="structCtrl" [matAutocomplete]="auto" (keyup.enter)="onStructureSelect(null)">
        <mat-autocomplete #auto="matAutocomplete">
          <mat-option *ngFor="let struct of filteredOptions | async"
                      [value]="struct.libelleLong"
                      (click)="onStructureSelect(struct)" (onSelectionChange)="onStructureSelect(struct)">
            {{struct.libelleLong}}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
    </div>

    <div class="row">
      <app-arborescence class="col-12"
        [dataTree]="dataTree"
        (selectedStructNodeEmitter)="onTreeNodeSelected($event)"></app-arborescence>
    </div>
  </mat-sidenav>

  <mat-sidenav-content>
    <div class="row mt-4 justify-content-center" *ngIf="selectedStructureId && !openedSidenav">
      <button mat-raised-button color="primary" (click)="sidenav.toggle()" *ngIf="!openedSidenav">
        {{'retour à la liste' | uppercase}}</button>
    </div>

    <app-structure-details [id]="selectedStructureId"></app-structure-details>
  </mat-sidenav-content>
</mat-sidenav-container>





