import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';
import {FormGroup} from '@angular/forms';

@Injectable()
export class LoaderService {

  loading: Subject<boolean> = new Subject<boolean>();

  constructor() {
    this.loading.next(false);
  }

  start(form?: FormGroup) {
    if (form) {
      form.disable();
    }
    this.loading.next(true);
  }

  stop(form?: FormGroup) {
    if (form) {
      form.enable();
    }
    this.loading.next(false);
  }
}
