import {Component, EventEmitter, Input, OnChanges, Output} from '@angular/core';
import {StructNode} from '../../objects';
import {LoaderService} from '../../services/loader.service';
import {StructureService} from '../../services/structure.service';
import {GlobalStateService} from '../../services/global-state.service';
import {TreeNode} from "primeng/api";

@Component({
  selector: 'app-arborescence',
  templateUrl: './arborescence.component.html',
  styleUrls: ['./arborescence.component.scss']
})
export class ArborescenceComponent implements OnChanges {

  @Output() selectedStructNodeEmitter: EventEmitter<StructNode> = new EventEmitter<StructNode>();
  @Input() dataTree: StructNode[];

  lazyStructTree: StructNode[];

  constructor(private loaderService: LoaderService,
              private globalStateService: GlobalStateService,
              private structureService: StructureService) {
    this.lazyStructTree = [];

    this.globalStateService.subscribe('refresh', (refreshEvent: boolean) => {
      if (refreshEvent) {
        this.retractAll();
        this.globalStateService.notifyDataChanged('refresh', false);
      }
    });
  }

  ngOnChanges(): void {
    if (this.dataTree) {
      this.lazyStructTree = this.dataTree;
    }
  }

  onNodeSelect(node: StructNode) {
    this.selectedStructNodeEmitter.emit(node);
  }

  loadNode(event: any) {
    const structNode: StructNode = {
      label: event.node.label,
      data: event.node.data,
      icon: event.node.icon,
      styleClass: null,
      leaf: event.node.leaf,
      parent: event.node.parent,
      children: event.node.children,
    };

    this.loaderService.start();
    this.structureService.getLazyNodeChildren(JSON.stringify(structNode, this.getCircularReplacer())).toPromise()
      .then(res => {
        event.node.children = res;
        this.loaderService.stop();
      })
      .catch(err => {
        console.error(err);
        this.loaderService.stop();
      });
  }

  onInputSearch(value: any) {
    this.expandAll();
    if (!value.data) {
      this.dataTree = this.lazyStructTree;
    }
  }

  private expandAll() {
    this.dataTree.forEach(node => {
      this.expandRecursive(node, true);
    });
  }

  private retractAll() {
    this.dataTree.forEach(node => {
      this.expandRecursive(node, false);
    });
  }

  private expandRecursive(node: TreeNode, isExpand: boolean) {
    node.expanded = isExpand;
    if (node.children) {
      node.children.forEach(childNode => {
        this.expandRecursive(childNode, isExpand);
      });
    }
  }

  private getCircularReplacer() {
    const seen = new WeakSet();
    return (key, value) => {
      if (typeof value === 'object' && value !== null) {
        if (seen.has(value)) {
          return;
        }
        seen.add(value);
      }
      return value;
    };
  }

}
