<div class="row" *ngIf="dataTree">
  <p-tree class="col-lg-12"
          [value]="dataTree" selectionMode="single"
          [filter]="false"
          (onNodeExpand)="loadNode($event)"
          (onNodeSelect)="onNodeSelect($event.node)"
          (input)="onInputSearch($event)"
          *ngIf="dataTree.length > 0">
  </p-tree>
</div>
