import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {LdapAccount} from '../objects';

@Injectable()
export class LdapService {

  constructor(private http: HttpClient) {
  }

  public getAllPersonsByStructureId(structureId: number): Observable<LdapAccount[]> {
    return this.http.get<LdapAccount[]>('/ldap/persons-by-structureId/' + structureId);
  }

}
