<div class="ml-4 mr-4" *ngIf="display">
  <div class="title header">
    <h1 class="h1">{{structure.libelleLong}}</h1>
    <p class="h5">{{structure.libelleCourt}} <span class="warn info-fermeture" *ngIf="structure.dateFermeture">(fermeture : {{structure.dateFermeture | date:'dd/MM/yyyy'}})</span></p>
    <p class="breadcrumb-custom">
      <ng-container *ngFor="let asc of ascendant; let first = first">
        <i class="fas fa-caret-right ml-2" *ngIf="!first"></i>
        <span class="clickable" [class.ml-2]="!first" (click)="onSelectedStucture(asc.structureId)">
                {{asc.libelleLong}}
              </span>
      </ng-container>

      <i class="fas fa-caret-right ml-2" *ngIf="structure.structureId !== 1"></i>
      <span class="ml-2">{{structure.libelleLong}}</span>

      <span *ngIf="children.length > 0">
        <i class="fas fa-caret-right ml-2"></i>
        <span class="ml-2 clickable" id="overlay-panel" data-toggle="dropdown" t aria-haspopup="true"
              aria-expanded="false"><i class="fas fa-ellipsis-h "></i></span>
        <ul class="dropdown-menu" aria-labelledby="overlay-panel">
          <li class="clickable small" (click)="onSelectedStucture(child.structureId)" *ngFor="let child of children">
            {{child.libelleLong}}</li>
        </ul>
      </span>
    </p>
  </div>

  <!--CARACTERISTIQUES GENERALES-->
  <h2 class="h4 title paragraph-title">Caractéristiques générales</h2>
  <div class="row">
    <div class="col-lg-12 mt-4" *ngIf="description?.description || description?.url">
      <mat-card>
        <mat-card-header>
          <mat-card-title>Description</mat-card-title>
        </mat-card-header>

        <mat-card-content>
          <img class="float-left mr-3" [src]="description.logoBase64" width="100" alt="logo"
               *ngIf="description.logoBase64">
          <div [innerHTML]="description.description"></div>
          <div *ngIf="description.url">
            <span class="subtitle">Site internet : </span>
            <a [href]="'http://' + description.url">{{description.url}}</a>
          </div>
        </mat-card-content>
      </mat-card>
    </div>

    <div class="col-lg-4 mt-4">
      <mat-card>
        <mat-card-header>
          <mat-card-title>Responsable</mat-card-title>
        </mat-card-header>

        <mat-card-content>{{responsables}}</mat-card-content>
      </mat-card>
    </div>

    <div class="col-lg-4 mt-4" *ngIf="tutelles.length > 0">
      <mat-card>
        <mat-card-header>
          <mat-card-title>Tutelles</mat-card-title>
        </mat-card-header>

        <mat-card-content>
          <ul>
            <li *ngFor="let tutelle of tutelles">{{tutelle.tutelleLib}}
              <div *ngIf="tutelle.rne" class="small" style="color: rgba(0, 0, 0, 0.54);">RNE {{tutelle.rne}}</div>
            </li>
          </ul>
        </mat-card-content>
      </mat-card>
    </div>

    <div class="col-lg-4 mt-4" *ngIf="codes.length > 0">
      <mat-card>
        <mat-card-header>
          <mat-card-title>Codes</mat-card-title>
        </mat-card-header>

        <mat-card-content>
          <ul>
            <li *ngFor="let code of codes">{{code.typeCode}} <span
              style="color: rgba(0, 0, 0, 0.54);">{{code.codeValue}}</span></li>
          </ul>
        </mat-card-content>
      </mat-card>
    </div>

    <div class="col-lg-4 mt-4" *ngIf="domaines.length > 0">
      <mat-card>
        <mat-card-header>
          <mat-card-title>Domaines</mat-card-title>
        </mat-card-header>

        <mat-card-content>
          <ng-container *ngFor="let domaine of domaines; let last = last">
            <p><span>{{domaine.domaineLib}}</span><span *ngIf="!last">{{', '}}</span></p>
          </ng-container>
        </mat-card-content>
      </mat-card>
    </div>
  </div>

  <!-- ADRESSES -->
  <ng-container *ngIf="adresses?.length > 0">
    <h2 class="h4 title paragraph-title">Adresses</h2>
    <div class="row">
      <div class="col-lg-6 mt-4" *ngFor="let adresse of adresses">
        <mat-card>
          <mat-card-header>
            <mat-card-title>{{adresse.typeAdresse}}
              <span class="clickable ml-2" (click)="displayMap(adresse)"><i class="fas fa-map"></i></span>
            </mat-card-title>
          </mat-card-header>

          <mat-card-content>
            <span class="ml-4 d-block">UNIVERSITE DE BORDEAUX</span>
            <span class="ml-4 d-block" *ngIf="structure.liensParent.length>0">{{structure.libelleLong}}</span>
            <span class="ml-4 d-block" *ngIf="adresse.pointGeo">{{adresse.pointGeo}}</span>
            <span
              class="ml-4 d-block">{{adresse.num }} {{adresse.compl }} {{adresse.typeVoierie }} {{adresse.libelleVoie}}</span>
            <span class="ml-4 d-block" *ngIf="adresse.mentionSpec">{{adresse.mentionSpec}}</span>
            <span class="ml-4 d-block">{{adresse.cp}} {{adresse.bureauDistrib}}</span>
          </mat-card-content>
        </mat-card>
      </div>
    </div>
  </ng-container>

  <!-- CONTACTS -->
  <ng-container *ngIf="contacts?.length > 0">
    <h2 class="h4 title paragraph-title">Contacts</h2>
    <div class="row">
      <div class="col-lg-6 mt-4" *ngFor="let contact of contacts">
        <mat-card>
          <mat-card-header>
            <mat-card-title>{{contact.intituleContact}}</mat-card-title>
          </mat-card-header>

          <mat-card-content>
        <span class="ml-4 d-block" *ngIf="contact.nomPrenom">
          <i class="fas fa-user mr-2"></i>{{contact.nomPrenom}}</span>
            <span class="ml-4 d-block" *ngIf="contact.mail">
          <i class="fas fa-at mr-2"></i><a [href]="'mailto:' + contact.mail">{{contact.mail}}</a></span>
            <span class="ml-4 d-block" *ngIf="contact.telephone">
          <i class="fas fa-phone mr-2"></i>{{contact.telephone}}</span>
            <span class="ml-4 d-block" *ngIf="contact.mobile">
          <i class="fas fa-mobile-alt mr-3"></i>{{contact.mobile}}</span>
            <span class="ml-4 d-block" *ngIf="contact.fax">
          <i class="fas fa-fax mr-2"></i>{{contact.fax}}</span>
          </mat-card-content>
        </mat-card>
      </div>
    </div>
  </ng-container>

  <!--PERSONNEL-->
    <ng-container *ngIf="persons?.length > 0">
      <h2 class="title paragraph-title">Personnel</h2>
      <div class="row">
        <div class="col-lg-12 mt-4">
          <mat-card>
              <app-liste-personnel [persons]="persons" [structure]="structure"
                                   (selectedStructure)="onSelectedStucture($event)">
              </app-liste-personnel>
          </mat-card>
        </div>
      </div>
    </ng-container>
