import {Component, OnInit} from '@angular/core';
import {StructNode, Structure} from '../../objects';
import {FormControl} from '@angular/forms';
import {Observable} from 'rxjs';
import {map, startWith} from 'rxjs/operators';
import {StructureService} from '../../services/structure.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-annuaire-des-structures',
  templateUrl: './annuaire-des-structures.component.html',
  styleUrls: ['./annuaire-des-structures.component.scss']
})
export class AnnuaireDesStructuresComponent implements OnInit {

  openedSidenav: boolean;
  dataTree: StructNode[];
  structures: Structure[];

  structCtrl: FormControl;
  filteredOptions: Observable<Structure[]>;

  selectedStructureId: number;

  constructor(private router: Router, private structureService: StructureService) {
    this.structCtrl = new FormControl();
  }

  ngOnInit() {
    Promise.all([this.getLazyStructTree(), this.getAllStructures()]).then(() => {
      this.openedSidenav = true;
    });
  }

  onTreeNodeSelected(structNode: StructNode) {
    this.selectedStructureId = structNode.data;
    this.closeSidenav();
  }

  onStructureSelect(structure: Structure) {
    if (structure) {
      this.selectedStructureId = structure.structureId;
      this.closeSidenav();
    }
  }

  private closeSidenav() {
    if (window.innerWidth < 1300) {
      this.openedSidenav = false;
    }
  }

  private getAllStructures(): Promise<void> {
    return new Promise<void>(resolve => {
      this.structureService.getAllStructures().subscribe(res => {
        this.structures = res;
        this.filteredOptions = this.structCtrl.valueChanges
          .pipe(
            startWith(''),
            map(value => this._filter(value))
          );
        resolve();
      });
    });
  }

  private getLazyStructTree(): Promise<void> {
    return new Promise<void>(resolve => {
      this.structureService.getLazyStructTree().subscribe(res => {
        this.dataTree = res;
        resolve();
      });
    });
  }

  private _filter(value: string): Structure[] {
    let options: Structure[];

    if (value && value.trim().length > 0) {
      const values: string[] = value.split(' ');
      options = this.structures
        .filter(structure => values.every(v =>
            structure.libelleLong
              .toLowerCase()
              .normalize('NFD')
              .replace(/[\u0300-\u036f]/g, '')
              .includes(
                v
                  .toLowerCase()
                  .normalize('NFD')
                  .replace(/[\u0300-\u036f]/g, ''))
          )
        );
    }
    return options;
  }

}
