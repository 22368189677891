import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';

@Injectable()
export class GlobalStateService {
  private data = new Subject<any>();
  private dataStream$ = this.data.asObservable();

  // tslint:disable-next-line:ban-types
  private subscriptions: Map<string, Array<Function>> = new Map<string, Array<Function>>();

  constructor() {
    this.dataStream$.subscribe(data => this._onEvent(data));
  }

  notifyDataChanged(event: string, value: any): void {
    const current = this.data[event];
    if (current !== value) {
      this.data[event] = value;

      this.data.next({
        event,
        data: this.data[event],
      });
    }
  }

  // tslint:disable-next-line:ban-types
  subscribe(event: string, callback: Function): void {
    const subscribers = this.subscriptions.get(event) || [];
    subscribers.push(callback);

    this.subscriptions.set(event, subscribers);
  }

  _onEvent(data: any): void {
    const subscribers = this.subscriptions.get(data.event) || [];

    subscribers.forEach(callback => {
      callback.call(null, data.data);
    });
  }
}
