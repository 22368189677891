import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Adresse, Code, Contact, Description, Domaine, Individu, StructNode, Structure, Tutelle} from '../objects';

@Injectable()
export class StructureService {

  constructor(private http: HttpClient) {
  }

  public getAllStructures(): Observable<Structure[]> {
    return this.http.get<Structure[]>('/structure/all-structures');
  }

  public getStructureById(structureId: number): Observable<Structure> {
    return this.http.get<Structure>('/structure/' + structureId);
  }

  public getAffectationsByIdnum(idnum: string): Observable<Structure[]> {
    return this.http.get<Structure[]>('/structure/affectations/' + idnum);
  }

  public getAscendantsByStructureId(structureId: number): Observable<Structure[]> {
    return this.http.get<Structure[]>('/structure/' + structureId + '/ascendants');
  }

  public getChildrenByStructureId(structureId: number): Observable<Structure[]> {
    return this.http.get<Structure[]>('/structure/' + structureId + '/enfants');
  }

  public getDescriptionByStructureId(structureId: number): Observable<Description> {
    return this.http.get<Description>('/structure/' + structureId + '/description');
  }

  public getAdressesByStructureId(structureId: number): Observable<Adresse[]> {
    return this.http.get<Adresse[]>('/structure/' + structureId + '/adresses');
  }

  public getContactsByStructureId(structureId: number): Observable<Contact[]> {
    return this.http.get<Contact[]>('/structure/' + structureId + '/contacts');
  }

  public getCodesByStructureId(structureId: number): Observable<Code[]> {
    return this.http.get<Code[]>('/structure/' + structureId + '/codes');
  }

  public getTutellesByStructureId(structureId: number): Observable<Tutelle[]> {
    return this.http.get<Tutelle[]>('/structure/' + structureId + '/tutelles');
  }

  public getDomainesByStructureId(structureId: number): Observable<Domaine[]> {
    return this.http.get<Domaine[]>('/structure/' + structureId + '/domaines');
  }

  public getResponsablesByStructureId(structureId: number): Observable<Individu[]> {
    return this.http.get<Individu[]>('/structure/' + structureId + '/responsables');
  }

  public getLazyStructTree(): Observable<StructNode[]> {
    return this.http.get<StructNode[]>('/structure/lazy-structure-tree');
  }

  public getLazyNodeChildren(structNode: string): Observable<StructNode[]> {
    return this.http.post<StructNode[]>('/structure/lazy-node-children', structNode);
  }

}
